import { useState, createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import ResearcherLogin from '../../service/ResearcherLogin';

export const ResearcherContext = createContext(null);

export const useResearcher = () => {
	return useContext(ResearcherContext);
};

export default function ResearcherProvider(props) {
	const history = useHistory();
	const [authChecked, setAuthChecked] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		ResearcherLogin.isLoggedIn().then(response => {
			setIsLoggedIn(response);
			setAuthChecked(true);
		});
	}, []);

	const handleLogout = () => {
		ResearcherLogin.logout().then(() => {
			setIsLoggedIn(false);
			history.push('/kutato');
		});
	};

	const value = {
		authChecked,
		isLoggedIn,
		handleLogout,
		setIsLoggedIn
	};

	return <ResearcherContext.Provider value={value}>{props.children}</ResearcherContext.Provider>;
}
