import { useResearcher } from '../provider/ResearcherProvider';

function LogoutMenu(props) {
	const { isLoggedIn, handleLogout } = useResearcher();

	if (isLoggedIn) {
		return (
			<li>
				<div
					className="menu-item-normal"
					onClick={() => {
						handleLogout();
						props.onLogout();
					}}
				>
					Kijelentkezés
				</div>
			</li>
		);
	}
	return <></>;
}

export default LogoutMenu;
