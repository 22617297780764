import React, { Component } from 'react';

export default class TagButton extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={'hashtag' + (this.props.active ? ' active' : '') + (!this.props.visible ? ' hidden' : '')} onClick={this.props.onClick}>
				#{this.props.data.name}
			</div>
		);
	}
}
