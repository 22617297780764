import React, { Component } from 'react';
import filterConfig from '../config/filterConfig.json';

export default class filterButton extends Component {
	constructor(props) {
		super(props);
	}

	handleChange() {
		this.props.onChange(this.props.item.value);
	}

	render() {
		return (
			<div className={'filter-button' + (this.props.active ? ' active' : '')} onClick={() => this.handleChange()}>
				<div className="orb">
					<div className="iconWrap">
						<img className="icon gray" src={filterConfig[this.props.item.value].iconGray} />
						<img className="icon white" src={filterConfig[this.props.item.value].iconWhite} />
						<img className="icon blue" src={filterConfig[this.props.item.value].iconBlue} />
					</div>
				</div>
				{this.props.count != null ? <div className="filter-count">{this.props.count}</div> : null}
				<div className="label">{this.props.item.label}</div>
			</div>
		);
	}
}
