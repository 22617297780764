import axios from 'axios';

class nBossConnection {
    constructor() {
        console.log("nBossConnection initialized");
        this.baseUrl = 'http' + (process.env.REACT_APP_API_SECURE === "true" ?  's' : '') + '://' + 
        process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? ':' + process.env.REACT_APP_API_PORT : '') + 
        '/' + process.env.REACT_APP_API_NAME + '/methods/';
        this.connection = axios.create({
            baseURL: this.baseUrl
        });
        
        this.errorHandler = (e) => console.log(e);

        this.connection.interceptors.response.use((response) => {
            return response
        }, (error) => {
            error.isnBossConnectionError = true;
            return Promise.reject(error);
        });

        window.addEventListener("unhandledrejection", (e) => {
            console.log(e);
            if( typeof e.reason.isnBossConnectionError !== "undefined" && e.reason.isnBossConnectionError )
                this.errorHandler(e.reason);
        });
    }

    setErrorHandler(errorHandler) {
        this.errorHandler = errorHandler;
    }
}

export default new nBossConnection();