import NamedDto from "../dto/NamedDto";
import PersonDataDto from "../dto/person/PersonDataDto";
import nBossConnection from "./nBossConnection";

class TagService {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = "tagControl";
    }

    /**
    * @param {number} id
    * @return {Promise<NamedDto>}
    */
    getPersonTagList(id) {
        return new Promise((resolve, reject) => {
            this.connection.post('/' + this.CONTROL + '/getPersonTagList', {
                id: id,
            }).then((response) =>
                resolve(response.data)
            ).catch(reject);
        });
    }

    /**
    * @param {number} id
    * @return {Promise<NamedDto>}
    */
    getContentTagList(id) {
        return new Promise((resolve, reject) => {
            this.connection.post('/' + this.CONTROL + '/getContentTagList', {
                id: id,
            }).then((response) =>
                resolve(response.data)
            ).catch(reject);
        });
    }
}

export default new TagService();