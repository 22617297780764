class ContentStore {
    constructor() {
        this.clear();
    }

    clear () {
        this.queryKey = null;
        this.filterList = null;
        this.tag = null;
    }
}
export default new ContentStore();